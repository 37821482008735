<template>
  <div class="-mt-12">
    <div class="grid grid-cols-1">
      <div class="pt-6">
        <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
          <div class="-mt-6">
            <div class="text-center">
            <div>
                <span class="inline-flex items-center justify-center rounded-md bg-white border-blackbox-bg border p-3 shadow-lg">
                  <aosIcon class="h-20 w-20 p-3 text-blackbox-bg" aria-hidden="true" />
                </span>
            </div>
            <h3 class="mt-8 text-lg font-medium tracking-tight text-gray-900">안드로이드용 설치파일 다운로드</h3>
              <a :href="androidLink" class="mt-6 inline-flex items-center rounded-md border border-transparent bg-blackbox-bg bg-opacity-90 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blackbox-bg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <ArchiveBoxArrowDownIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                지금 설치하기
              </a>
            </div>

            <hr class="my-5" />

            <h4>설치가 안되나요?</h4>
            <p class="my-5 text-base text-gray-500">기존에 애플리케이션이 설치되어 있을 때 재 설치시가 되지 않는 경우에는, 아래와 같이 Clean-Remove 한 후 다시 설치를 시도해야 합니다.</p>
            <div class="w-full overflow-x-auto touch-pan-x flex">
              <img class="mr-2" width="180" src="@/assets/android1.png" />
              <img class="mr-2" width="180" src="@/assets/android2.png" />
              <img class="mr-2" width="180" src="@/assets/android3.png" />
            </div>
            <h4 class="my-5">최신 Galaxy 업데이트로 인한 보안 정책으로 인해서 LSS 설치/업데이트가 진행되지 않을 수 있어요. 아래 설명을 따라 설정을 변경해주세요.</h4>
            <li class="my-5 text-base text-gray-500">설정 -> 애플리케이션 -> LSS 삭제 -> LSS 관련 파일 모두 삭제 후 재설치</li>
            <li class="my-5 text-base text-gray-500">설정 -> 보안 및 개인정보 보호 -> 보안위험 자동차단 해제</li>
            <li class="my-5 text-base text-gray-500">설정 -> 보안 및 개인정보 보호 -> 앱 보안 -> 앱 보호 -> 우측 상단 점 세 개 -> 앱 보호 설정 해제</li>
            <li class="my-5 text-base text-gray-500">설정 -> 보안 및 개인정보 보호 -> 기타 보안 설정 -> 출처를 알 수 없는 앱 설치 -> 내 파일 / 삼성 인터넷 / Chrome 등 파일 설치 관련 모두 허용</li>
            <li class="my-5 text-base text-gray-500">(화면 해상도가 설정이 있는 경우) 설정 -> 디스플레이 -> 화면 해상도 HD로 변경</li>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ArchiveBoxArrowDownIcon } from '@heroicons/vue/24/outline'
import aosIcon from '@/icons/android'
import {inject} from "vue";
const androidLink = inject('androidLink')

</script>
